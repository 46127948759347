import { backend } from '@/app.config';
const INTERACTIVE_PLAYER_URL = backend.interactive_player_url;
const MEDIA_PLAYER_URL = backend.media_player_url

import { JobStatusType, ContentType } from '@/utils/enums';

export const playerDirectUrl = ( content, appToken ) => (
    `${INTERACTIVE_PLAYER_URL}?hash=${content.hash}&token=${appToken}`
);

export const playerMediaUrl = ( media, appToken ) => (
    `${MEDIA_PLAYER_URL}?mediaId=${media.id}&token=${appToken}`
);

export function isFirstMediaError ( content ) {
    const firstMedia = firstMediaInformation( content ) || {};

    return firstMedia.transcodeStatus === JobStatusType.ERROR;
}

export function isMediaReady ( media ) {
    const { transcodeStatus } = media;

    return transcodeStatus !== JobStatusType.IN_PROGRESS
        && transcodeStatus !== JobStatusType.ERROR;
}

export function isFirstMediaReady ( content ) {
    const firstMedia = firstMediaInformation( content ) || {};
    const { transcodeStatus } = firstMedia;

    return transcodeStatus !== JobStatusType.IN_PROGRESS
        && transcodeStatus !== JobStatusType.ERROR;
}

export function firstMediaInformation ( content, defaultValue ) {
    const { ref, type } = content;

    if ( type === ContentType.VOD )
        return ref?.primaryFormat?.media;
    if ( type === ContentType.STREAM )
        return ref?.cameras[0]?.media;
    if ( type === ContentType.INTERACTIVE )
        return getSceneMedia( ref?.interactive?.scenes[0]);

    return defaultValue;
}

export const getSceneMedia = scene => (
    scene?.sequences[0]?.media
);

export function isMediaError ( media ) {
    return media.transcodeStatus === JobStatusType.ERROR;
}