<template>
  <iframe
    v-if="isFirstMediaReady"
    class="iconic-player"
    :src="videoIFrameUrl"
    allowfullscreen
    width="100%"
    height="100%"
    title="Player"
  />
  <div
    v-else-if="isFirstMediaError"
    class="iconic-player iconic-player-not-ready transcode-error"
  >
    <p>{{ $t("Player.TranscodeError") }}</p>
  </div>
  <div v-else class="iconic-player iconic-player-not-ready">
    <p>{{ $t("Player.PreviewIsUnavailable") }}</p>
  </div>
</template>

<script>
import {
  playerMediaUrl,
  playerDirectUrl,
  isMediaReady,
  isMediaError,
  isFirstMediaReady,
  isFirstMediaError,
} from "@/utils/player";

export default {
  name: "IconicPlayer",
  props: {
    // references Content type api data: VodRef, InteractiveRef, StreamRef or AdRef
    content: { type: Object, default: null },

    // references Media type api data
    media: { type: Object, default: null },

    orgId: { type: String, required: true },
  },

  data: () => ({
    isMediaReady,
    videoIFrameUrl: null,
  }),

  computed: {
    isFirstMediaReady() {
      if (this.content) return isFirstMediaReady(this.content);

      if (this.media) return isMediaReady(this.media);

      return false;
    },
    isFirstMediaError() {
      if (this.content) return isFirstMediaError(this.content);

      if (this.media) return isMediaError(this.media);

      return false;
    },
  },

  async mounted() {
    this.videoIFrameUrl = await this.createVideoIFrameUrl();
  },

  methods: {
    async getOrCreateAppToken() {
      const res = await this.$store.dispatch("getAppTokenForPlayer", {
        orgId: this.orgId,
      });
      if (res)
        this.$store.playerToken = res?.getOrCreateHtmlApplicationToken?.token?.value;
      return res?.getOrCreateHtmlApplicationToken?.token?.value;
    },

    async createVideoIFrameUrl() {
      const html5AppToken = await this.getOrCreateAppToken();

      if (this.content) return playerDirectUrl(this.content, html5AppToken);

      return playerMediaUrl(this.media, html5AppToken);
    },
  },
};
</script>

<style scoped>
iframe {
  border: 0;
}

.iconic-player-not-ready {
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.iconic-player-not-ready.transcode-error {
  background: rgba(200, 0, 0, 0.3);
}

.iconic-player-not-ready p {
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  margin: 0;
  padding: 0;
}

@media (max-width: 900px) {
  .iconic-player {
    width: 100%;
    height: 225px;
  }
}
</style>
