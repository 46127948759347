<template>
  <div>
    <h1 class="uk-text-left"><a @click="goBack()" style="text-decoration: none;"><span uk-icon="icon: chevron-left; ratio: 2"></span> {{ $t('Pages.InteractiveVideos.EditIV') }}</a></h1>
    <hr />
    <div>
      <div class="uk-grid-divider" uk-grid>
        <div class="uk-width-expand@m">
          <div class="uk-margin-top uk-margin-left">
            <div v-if="firstLinkedContent" class="">
              <IconicPlayer :content="firstLinkedContent" :org-id="orgId" />
            </div>
            <form class="uk-form-stacked uk-margin-large uk-text-left" @submit.prevent="saveNewIV();">
              <div class="uk-margin-small">
                <label class="uk-form-label" for="iv-name">{{ $t( 'Pages.InteractiveVideos.Name' ) }} <span class="uk-text-danger">*</span></label>
                <div class="uk-form-controls">
                  <input class="uk-input" id="iv-name" type="text" 
                    :placeholder="$t( 'Pages.InteractiveVideos.Placeholder.Name' )" v-model="form.ivName"
                    @keyup="validateInput( 'name' )">
                  <div class="tooltip" v-if="!form.ivValid">
                    {{ $t( 'Generic.Errors.Min2Chars' ) }}
                  </div>
                </div>
              </div>
              <div class="uk-margin">
                <label class="uk-form-label" for="form-stacked-text">{{ $t( 'Pages.InteractiveVideos.ID' ) }}</label>
                <div class="uk-form-controls">
                  {{ selectedIV.id }}
                </div>
              </div>
              <div class="uk-margin">
                <label class="uk-form-label" for="form-stacked-text">{{ $t( 'Pages.InteractiveVideos.Created' ) }}</label>
                <div class="uk-form-controls">
                  {{ selectedIV.createdAt | date }}
                </div>
              </div>
              <div class="uk-form-controls uk-float-right uk-margin-top">
                <button v-if="!saving" class="uk-button uk-button-primary" type="button" @click.prevent="saveIVChanges();">{{ $t( 'Actions.Save' ) }}</button>
                <button v-else class="uk-button uk-button-primary" type="button" disabled><div uk-spinner="ratio: 0.5"></div></button>
              </div>
            </form>
          </div>
        </div>
        <div class="uk-width-1-2 uk-text-left">
          <div class="uk-child-width-expand@s" uk-grid>
            <div>
              <h3>{{ $t( 'Pages.InteractiveVideos.TotalScenes' ) }}: {{ scenes.length }}</h3>
            </div>
            <div>
                <a :href="editorLink" class="uk-margin-right uk-float-right" style="text-decoration:none;">{{ $t( 'Actions.OpenEditor' ) }}</a>
            </div>
          </div>
          <dl
            v-for="(scene, i) in scenes" 
            :key="i"
            class="uk-description-list"
          >
            <dd>{{ scene.name }}</dd>
            <dd class="uk-text-meta">{{ $t( 'Pages.InteractiveVideos.ID' ) }}: {{ scene.id }}</dd>
            <dd class="uk-text-meta">{{ $t( 'Pages.InteractiveVideos.TotalSequence' ) }}: {{ scene.sequences ? scene.sequences.length : 0 }}</dd>
            <dd class="uk-text-meta">{{ $t( 'Pages.InteractiveVideos.Created' ) }}: {{ scene.sequences && scene.sequences.length > 0 ? scene.sequences[0].createdAt : '' | date }}</dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { iv } from '@/app.config';
import Notification from '@/components/util/Notification';
import IconicPlayer from '@/components/IconicPlayer';

export default {
  name: 'InteractiveVideoDetail',
  components: { IconicPlayer },
  data() {
    return {
      ivEditor: iv.editor,
      form: {},
      saving: false,
      orgId: this.$route.params.id
    }
  },
  computed: {
    ...mapState({
      selectedIV: state => state.venom.selected.interactive || {}
    }),
    editorLink() {
      return this.ivEditor + '/?load_story=' + this.selectedIV.id;
    },
    scenes() {
      return this.selectedIV.scenes;
    },
    firstLinkedContent () {
      return ( this.selectedIV.linkedContents || [])[0];
    }
  },
  watch: {
    selectedIV() {
      this.form = {
        ivName: this.selectedIV.name,
        ivValid: true
      };
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.prevRoute = from;
    })
  },
  methods: {
    goBack() {
      if (this.prevRoute.name && this.prevRoute.name !== 'Login') {
        this.$router.go(-1);
      } else {
        this.$router.push({ name: 'InteractiveVideo'});
      }
    },
    async selectIV() {
      const ivId = this.$route.params.ivId;
      await this.$store.dispatch('selectIV', { ivId });
    },
    defaultFormData() {
      this.form = {
        ivName: this.selectedIV.name,
        ivValid: true
      };
    },
    validateInput( field ) {
      let isValid = false;
      if ( field === 'name' && this.form.ivName.trim().length >= 2 ) {
        isValid = true;
      }
      this.form.ivValid = isValid;
      return isValid;
    },
    async saveIVChanges() {
      const isValidName = this.validateInput( 'name' );
      if ( isValidName ) {
        this.saving = true;
        const ivId = await this.$store.dispatch( 'updateInteractive', {
          ivData: {
            interactive: this.selectedIV.id,
            name: this.form.ivName
          }
        } );
        this.saving = false;
        if( ivId ) {
          Notification.showNotification( this.$t( 'Generic.Messages.Successful' ), this.$t( 'Pages.InteractiveVideos.Notification.ChangesSaved' ) );
        }
      }
    }
  },
  async created() {
    await this.selectIV();
  },
  mounted() {
    this.defaultFormData();
  }
}
</script>

<style lang="scss" scoped>
.tooltip {
  color: #e69393;
}
.iconic-player {
  width: "100%";
  height: 365px;
  border: 1px solid var(--border-color);
}
</style>